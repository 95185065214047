import firebase from 'firebase';


var firebaseConfig = {
    
    apiKey: "AIzaSyBLzKVwoSijL9kxZG4p5Y8qZqwnrUpOUPk",
    authDomain: "cwi-con.firebaseapp.com",
    projectId: "cwi-con",
    storageBucket: "cwi-con.appspot.com",
    messagingSenderId: "1031227314036",
    appId: "1:1031227314036:web:56fc32a71ce3e05b39a621",
    measurementId: "G-8XS692346H"

  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
  firebase.analytics();
//   const messaging = firebase.messaging();


export default firebase;
